window.rwd || (window.rwd = {});

;(function($package, ScreenSize, UIComponent) {
$package.CommonExpandable = CommonExpandable;
extendz(CommonExpandable, UIComponent);

    function CommonExpandable(node, options) {
        UIComponent.call(this, node);
        this.options = $.extend({}, CommonExpandable.defaults, options);
        var $node = $(this.node);
        this.$ui = {
            node: $node,
            list: $node.find(this.options.listSelector),
            items: $node.find(this.options.itemsSelector),
            buttons: $node.find(this.options.itemsButton),
            expandButton: $node.find(this.options.expandSelector)
        }
        this.init();
        CommonExpandable.all.push(this);
    }
    CommonExpandable.all = [];

    CommonExpandable.prototype.init = function() {
        var that = this;
        const {
            expand_aria_label,
            expanded_aria_label,
        } = that.options;

        if(expand_aria_label || expanded_aria_label) {
            this.setAriaLabel(expand_aria_label, "Rozwiń");
        };

        if(that.$ui.items.length === Number(that.options.number_of_visible_questions)) return;
        this.toggleListState();
        this.$ui.node.onPage("click", this.options.expandSelector, function() {
            that.uiToggle();
        });
    }

    CommonExpandable.prototype.toggleListState = function() {
        const items = this.$ui.items;
        const numberOfButtonsToHide = items.length - this.options.number_of_visible_questions;
        const buttonsToHide =  items.slice(-numberOfButtonsToHide);
        buttonsToHide.toggle();
        buttonsToHide.first().find(this.$ui.buttons).focus();
    }

    CommonExpandable.prototype.setAriaLabel =  function(ariaLabel, alternativeAriaLabel) {
        const expandAriaLabelText = ariaLabel ? ariaLabel : alternativeAriaLabel;
        const $expandButton = $(this.$ui.expandButton);
        $expandButton
            .attr('aria-label', expandAriaLabelText);
    }

    CommonExpandable.prototype.toggleButtonState = function() {
        const {
            number_of_visible_questions,
            expand_label,
            expand_aria_label,
            expanded_label,
            expanded_aria_label,
            labelReplace
        } = this.options;

        const $list = $(this.$ui.list);
        const $expandButton = $(this.$ui.expandButton);
        $list.toggleClass('state-expanded');
        const isExpanded = $list.hasClass('state-expanded');
        const labelButtonShow = !isExpanded ? expand_label : expanded_label;
        if(expand_aria_label || expanded_aria_label) {
            const ariaButtonShow = !isExpanded ?  this.setAriaLabel(expand_aria_label, "Rozwiń") : this.setAriaLabel(expanded_aria_label, "Zwiń");
            $expandButton.attr('aria-label', ariaButtonShow);
        }
        $expandButton
        .attr('aria-expanded', isExpanded)

        const commonLabelReplace = ($element, content) => $element.contents().each((_, node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                node.textContent = content;
            }
        });
        (labelReplace || commonLabelReplace)($expandButton, labelButtonShow);
    }

    CommonExpandable.prototype.uiToggle = function() {
        this.toggleListState();
        this.toggleButtonState();
    }


})(rwd, rwd.ScreenSize, rwd.UIComponent);